// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$logiq-frontend-players-primary: mat.define-palette(mat.$indigo-palette);
$logiq-frontend-players-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$logiq-frontend-players-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$logiq-frontend-players-theme: mat.define-light-theme(
	(
		color: (
			primary: $logiq-frontend-players-primary,
			accent: $logiq-frontend-players-accent,
			warn: $logiq-frontend-players-warn,
		),
	)
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($logiq-frontend-players-theme);

//Fonts
@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 100;
	font-display: swap;
	src: url("/assets/fonts/Inter-Thin.woff2?v=3.13") format("woff2"), url("/assets/fonts/Inter-Thin.woff?v=3.13") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: italic;
	font-weight: 100;
	font-display: swap;
	src: url("/assets/fonts/Inter-ThinItalic.woff2?v=3.13") format("woff2"), url("/assets/fonts/Inter-ThinItalic.woff?v=3.13") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src: url("/assets/fonts/Inter-ExtraLight.woff2?v=3.13") format("woff2"), url("/assets/fonts/Inter-ExtraLight.woff?v=3.13") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: italic;
	font-weight: 200;
	font-display: swap;
	src: url("/assets/fonts/Inter-ExtraLightItalic.woff2?v=3.13") format("woff2"), url("/assets/fonts/Inter-ExtraLightItalic.woff?v=3.13") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url("/assets/fonts/Inter-Light.woff2?v=3.13") format("woff2"), url("/assets/fonts/Inter-Light.woff?v=3.13") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: italic;
	font-weight: 300;
	font-display: swap;
	src: url("/assets/fonts/Inter-LightItalic.woff2?v=3.13") format("woff2"), url("/assets/fonts/Inter-LightItalic.woff?v=3.13") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url("/assets/fonts/Inter-Regular.woff2?v=3.13") format("woff2"), url("/assets/fonts/Inter-Regular.woff?v=3.13") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url("/assets/fonts/Inter-Italic.woff2?v=3.13") format("woff2"), url("/assets/fonts/Inter-Italic.woff?v=3.13") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url("/assets/fonts/Inter-Medium.woff2?v=3.13") format("woff2"), url("/assets/fonts/Inter-Medium.woff?v=3.13") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: italic;
	font-weight: 500;
	font-display: swap;
	src: url("/assets/fonts/Inter-MediumItalic.woff2?v=3.13") format("woff2"), url("/assets/fonts/Inter-MediumItalic.woff?v=3.13") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url("/assets/fonts/Inter-SemiBold.woff2?v=3.13") format("woff2"), url("/assets/fonts/Inter-SemiBold.woff?v=3.13") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: italic;
	font-weight: 600;
	font-display: swap;
	src: url("/assets/fonts/Inter-SemiBoldItalic.woff2?v=3.13") format("woff2"), url("/assets/fonts/Inter-SemiBoldItalic.woff?v=3.13") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url("/assets/fonts/Inter-Bold.woff2?v=3.13") format("woff2"), url("/assets/fonts/Inter-Bold.woff?v=3.13") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url("/assets/fonts/Inter-BoldItalic.woff2?v=3.13") format("woff2"), url("/assets/fonts/Inter-BoldItalic.woff?v=3.13") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src: url("/assets/fonts/Inter-ExtraBold.woff2?v=3.13") format("woff2"), url("/assets/fonts/Inter-ExtraBold.woff?v=3.13") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: italic;
	font-weight: 800;
	font-display: swap;
	src: url("/assets/fonts/Inter-ExtraBoldItalic.woff2?v=3.13") format("woff2"), url("/assets/fonts/Inter-ExtraBoldItalic.woff?v=3.13") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: url("/assets/fonts/Inter-Black.woff2?v=3.13") format("woff2"), url("/assets/fonts/Inter-Black.woff?v=3.13") format("woff");
}

@font-face {
	font-family: "Inter";
	font-style: italic;
	font-weight: 900;
	font-display: swap;
	src: url("/assets/fonts/Inter-BlackItalic.woff2?v=3.13") format("woff2"), url("/assets/fonts/Inter-BlackItalic.woff?v=3.13") format("woff");
}

// Colors
$grey-color: #878d96;

//...
body {
	background-color: #f2f4f8;
	font-family: "Inter", sans-serif;
	margin: 0;
	font-size: 14px;
	padding-bottom: 12px;
}

//Text sizes
p {
	margin: 3px 0;
}
h2 {
	font-size: 20px;
}

h3 {
	font-size: 14px;
}

.h2 {
	font-size: 20px;
	font-weight: 700;
}

.h3 {
	font-size: 14px;
	font-weight: 700;
}

.small-text {
	font-size: 12px;
}

.micro-text {
	font-size: 10px;
}

//Colors
.grey {
	color: $grey-color;
}

//Buttons
.button {
	height: 40px;
	border-radius: 8px;
	color: #08365d;
	background-color: #ffffff;
	font-weight: 700;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid #08365d;
	cursor: pointer;

	&:hover {
		background-color: #08365d;
		color: #fff;
		text-decoration: none;
		/* cursor: pointer;
		text-decoration: underline;
		background-color: rgba(29, 92, 255, 0.1); */
	}

	&:disabled:hover {
		color: #d91e28;
		border: 2px solid #d91e28;
		background-color: rgba(217, 30, 40, 0.1);
	}

	&.active {
		background-color: #08365d;
		color: #fff;
		text-decoration: none;
	}
}

.button:disabled {
	opacity: 0.7;
}

.disabled {
	color: #878d96;
}

.no-pointer {
	cursor: not-allowed;
	pointer-events: none;
}

.big-button {
	width: 232px;
}

.medium-button {
	width: 32px;
	height: 32px;
}

.small-button {
	width: 80px;
}

//Buttons colors
.red-button {
	color: #d91e28;
	border: 2px solid #d91e28;

	&:hover {
		background-color: #d91e28;
		color: #fff;
	}
}

.grey-button {
	color: #878d96;
	border: 2px solid #878d96;

	&:hover {
		background-color: #878d96;
		color: #fff;
	}
}

//Buttons images
.play_default {
	background-image: url("/assets/play_default.svg");
	border-radius: 8px;
}
.play_default:hover {
	background-image: url("/assets/play_hover.svg");
	background-color: rgba(29, 92, 255, 0.1);
}

.shift-settings {
	background-image: url("/assets/icoAddtoplaylist.svg");
	background-repeat: no-repeat;
	background-position: center;
	margin-right: 5px;
	transition: 0.3s;
	cursor: pointer;

	&:hover {
		transform: rotate(90deg);
	}
}
.added_to_playlist {
	background-image: url("/assets/icoOnplaylist.svg");
	background-repeat: no-repeat;
	background-position: center;
	margin-right: 5px;
}

.close {
	background-image: url("/assets/icoClose.svg");
	background-repeat: no-repeat;
	background-position: center;
	margin-right: 5px;
}

.next_default {
	background-image: url("/assets/next_default.svg");
	border-radius: 8px;

	&.disabled {
		background-image: url("/assets/next_disabled.svg") !important;
	}
	&.active {
		background-image: url("/assets/next_active.svg") !important;
	}
}
.next_default:hover {
	background-image: url("/assets/next_hover.svg");
	background-color: rgba(29, 92, 255, 0.1);
}

.info-icon {
	background-image: url("/assets/icoInfo.svg");
}

.edit-icon {
	background-image: url("/assets/icoEdit.svg");
}

.delete-icon {
	background-image: url("/assets/icoTrash.svg");
}

//Marks
.mark {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 10px;
	color: #fff;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background-color: #000;
}

.mark-green {
	background-color: #24a248;
}

.mark-red {
	background-color: #d91e28;
}

//Circles button
.circle {
	/* border: 2px solid #c1c7cd;
	border-radius: 50%;
	position: relative;

	&.medium-button {
		width: 28px;
		height: 28px;
	}

	&.active-circle {
		border: 2px solid #08365d;
	}

	.inner-circle {
		position: absolute;
		width: 20px;
		height: 20px;
		background: linear-gradient(#4985ff, #1d5cff);
		border-radius: 50%;
		left: 50%;
		margin-left: -10px;
		top: 50%;
		margin-top: -10px;
	} */
	cursor: pointer;
	background-image: url("/assets/check_default.svg");

	&:hover {
		background-image: url("/assets/check_hover.svg");
	}

	&.active-circle {
		background-image: url("/assets/check_active.svg");
	}

	&.disabled-circle {
		opacity: 0.4;
		pointer-events: none;
	}
}

.modal-content {
	width: 302px;
}

//Widgets items
.widget-item {
	padding-top: 8px;
	padding-bottom: 8px;

	&.active-video {
		background-color: rgba(29, 92, 255, 0.1);
	}
}

//Icons
.icon {
	width: 36px;
	height: 36px;
}

//Content
.container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-inline: 12px;
}

.flex {
	display: flex;
	align-items: center;
}

.center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.gap {
	margin-left: 12px;
}

.bottom-gap {
	margin-bottom: 12px;
}

.hover :hover {
	cursor: pointer;
}

.bold {
	font-weight: bold;
}

html,
body {
	height: 100%;
}
body {
	margin: 0;
	font-family: Inter, "Helvetica Neue", sans-serif;
}

select,
button,
.button {
	font-size: 14px;
}
